/* You can add global styles to this file, and also import other style files */
@import "./colors";
@import "./bootstrap";
@import "./typography";
@import "./theme.scss";

html,
body {
  height: 100%;
}

.backdrop-blurred {
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  filter: blur(5px);
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

button.mat-mdc-button,
button.mat-mdc-raised-button,
button.mat-mdc-stroked-button {
  font-size: 16px;
  line-height: 40px;
  min-height: 48px;
  border-radius: 24px;
  padding: 0 20px;
  box-shadow: none !important;

  &.small-button {
    min-height: unset;
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 14px;
    mat-icon {
      font-size: 14px;
      height: 14px;
      width: 14px;
      margin-right: 4px;
    }
  }
  &.close-button {
    padding: 0 16px;
    mat-icon {
      font-size: 18px;
      height: 18px;
      width: 18px;
    }
  }
}

.mat-mdc-slide-toggle {
  .mat-mdc-slide-toggle-content {
    color: $color-unselected;
  }
  .mat-mdc-slide-toggle-bar {
    width: 40px;
    height: 24px;
    border-radius: 32px;
    background-color: $color-almost-white;

    .mat-mdc-slide-toggle-thumb-container {
      top: 2px;
      left: 2px;
    }
  }
}

.mat-mdc-checked {
  .mat-mdc-slide-toggle-bar {
    background-color: $color-green !important;
    .mat-mdc-slide-toggle-thumb {
      background-color: $color-white;
    }
  }
  .mat-mdc-slide-toggle-content {
    color: $color-black;
  }
}

.mat-mdc-card {
  @include media-breakpoint-up(md) {
    min-height: 422px;
  }
}

.mat-mdc-card:not([class*="mat-elevation-z"]) {
  border: none;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.08),
    0 4px 8px 0 rgba(0, 0, 0, 0.08), inset 0 1px 0 0 $box-shadow-color;
}

mat-mdc-dialog-container.mat-mdc-dialog-container {
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08),
    0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 32px 64px 0 rgba(0, 0, 0, 0.08),
    inset 0 1px 0 0 $box-shadow-color;
  @include media-breakpoint-down(sm) {
    padding: 16px;
  }
}

.mat-mdc-dialog-content {
  @include media-breakpoint-down(sm) {
    margin: 0 -16px !important;
    padding: 0 16px !important;
  }
}

.with-gap {
  row-gap: 6px;
  column-gap: 12px;
}

@include media-breakpoint-down(md) {
  .cdk-overlay-pane {
    max-width: 95vw !important;
  }
}
