@import "./bootstrap_essentials";
@import "./variables.scss";
@import "../assets/fonts/inter";
@import "../assets/fonts/material-icons";

$inter: "Inter", Helvetica, sans-serif;

h1 {
  font-size: 32px;
  line-height: 44px;
  font-weight: 500;
  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}

h2 {
  font-size: 24px;
  line-height: 32px;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
    line-height: 28px;
  }
}

h3 {
  font-size: 20px;
  line-height: 28px;
  @include media-breakpoint-down(sm) {
    font-size: 18px;
    line-height: 26px;
  }
}

h4 {
  font-size: 18px;
  line-height: 24px;
  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

h5 {
  font-size: 18px;
  line-height: 24px;
  @include media-breakpoint-down(md) {
    font-size: 14px;
    line-height: 20px;
  }
}

html,
body,
input,
textarea,
span,
p,
td,
div:not(.dsw-footer, .dsw-footer-links, .dsw-footer-copyright) {
  font-family: $inter !important;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
.mat-mdc-dialog-title {
  color: $color-black;
  font-family: $inter !important;
  font-weight: 500;
  margin-bottom: 0;
}

button,
button span,
th,
th span,
a,
a span {
  font-family: $inter !important;
  font-weight: 600;
}

input.mat-mdc-input-element {
  color: $color-unselected;
  &[disabled] {
    color: $color-disabled;
  }
}

a:not(.dsw-link, .dsw-carrier-link) {
  color: $color-black !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none !important;
  cursor: pointer;
}

p {
  font-size: 16px;
  color: $color-black;
}

.text-small {
  font-size: 13px;
}
