$color-white: #fff;
$color-almost-white: #d0d0d0;
$color-grey-light: #f5f5f5;
$color-grey: #858585;
$color-black: #000;
$color-red: #e05132;
$color-viessmann-red: #ff3e17;
$color-link: #0056b3;

$color-green: #75c41b;
$color-yellow: #f9ab02;

$box-shadow-color: #dedede;

$color-disabled: #858585;
$color-unselected: #545454;

$colors: (
  "white": $color-white,
  "grey-light": $color-grey-light,
  "grey": $color-grey,
  "black": $color-black,
  "red": $color-red,
  "green": $color-green,
  "yellow": $color-yellow,
  "viessmann-red": $color-viessmann-red,
  "link": $color-link,
);

$color-light-grey: #f6f6f6;
$color-black-01: #f0f0f0;
$color-black-02: #dedede;
$color-black-03: #d0d0d0;
$color-black-04: #858585;
$color-black-05: #545454;
$color-black-09: #000000;

$color-vitorange: #ff3e17;
$color-blue: #ff3e17;
$color-green: #75c41b;

$navbar-height: 72px;

$base_padding_small: 30px;
$base_padding_medium: 50px;
$base_padding_large: 10vw;
$base_padding_very_large: 20vw;
